export enum COOKIE_NAME {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  ADMIN_ACCESS_TOKEN = 'admin_access_token',
  ADMIN_REFRESH_TOKEN = 'admin_refresh_token',
  PUBLISHER_ACCESS_TOKEN = 'publisher_access_token',
  PUBLISHER_REFRESH_TOKEN = 'publisher_refresh_token',
  PUBLISHER_SKIP_BUSINESS_DOC_STEP = 'publisher_skip_business_doc_step',
  TIME_ZONE = 'time_zone',
}
