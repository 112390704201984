import { useEffect } from 'react';

import Cookies from 'js-cookie';

import { COOKIE_NAME } from '@shared/constants/cookies';

function useSetUserTimezone(timeZone?: string) {
  useEffect(() => {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timeZone !== currentTimeZone) {
      Cookies.set(COOKIE_NAME.TIME_ZONE, currentTimeZone);
    }
  }, [timeZone]);
}

export default useSetUserTimezone;